.noty_theme__sunset.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  border-radius: 2px;
  position: relative; }
  .noty_theme__sunset.noty_bar .noty_body {
    padding: 10px;
    font-size: 14px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }
  .noty_theme__sunset.noty_bar .noty_buttons {
    padding: 10px; }

.noty_theme__sunset.noty_type__alert,
.noty_theme__sunset.noty_type__notification {
  background-color: #073B4C;
  color: #fff; }
  .noty_theme__sunset.noty_type__alert .noty_progressbar,
  .noty_theme__sunset.noty_type__notification .noty_progressbar {
    background-color: #fff; }

.noty_theme__sunset.noty_type__warning {
  background-color: #FFD166;
  color: #fff; }

.noty_theme__sunset.noty_type__error {
  background-color: #EF476F;
  color: #fff; }
  .noty_theme__sunset.noty_type__error .noty_progressbar {
    opacity: .4; }

.noty_theme__sunset.noty_type__info,
.noty_theme__sunset.noty_type__information {
  background-color: #118AB2;
  color: #fff; }
  .noty_theme__sunset.noty_type__info .noty_progressbar,
  .noty_theme__sunset.noty_type__information .noty_progressbar {
    opacity: .6; }

.noty_theme__sunset.noty_type__success {
  background-color: #06D6A0;
  color: #fff; }
